import styled from '@emotion/styled'
import tw from 'twin.macro'

export const BlogListItemWrapper = styled.div`
  ${tw`
    mb-8
  `}
`

export const BlogListItemName = styled.div`
  ${tw`
    my-6
    space-y-3
  `}
  h5 {
    ${tw`
      pl-3
      text-base
      leading-6
      font-medium
      hover:text-indigo-600
      text-purple-900
      border-l-4 
      border-indigo-600
    `}
  }
`

export const BlogListItemThemeAndAuthor = styled.div`
  ${tw`
    w-full
    flex
    flex-row
  `}
  p {
    ${tw`
      truncate
      ml-2
      text-sm
      text-purple-300
    `}
  }
`
