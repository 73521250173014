import styled from '@emotion/styled'
import tw from 'twin.macro'

export const BlogCardWrapper = styled.div`
  ${tw`  
    w-full
  `}
`

export const BlogCardBackground = styled.div`
  ${tw`
    bg-white
    hover:shadow-lg
    hover:transform 
    hover:-translate-y-1
    hover:transition 
    hover:ease-in-out 
    hover:duration-700
    overflow-hidden
    rounded-lg
    flex 
    flex-col
  `}
`

export const BlogCardIllustration = styled.figure`
  ${tw`
    flex-shrink-0
  `}
  img {
    ${tw`
      h-80 w-full object-cover
    `}
  }
`

export const BlogCardHeader = styled.div`
  ${tw`
    px-4
    py-5
    border-b
    border-gray-200
    sm:px-6
  `}
`

export const BlogCardHeaderTitle = styled.div`
  ${tw`
    mt-6
    leading-tight
    md:leading-none
    text-4xl
    font-bold
    text-purple-900
  `}
`

export const BlogCardHeaderInfo = styled.div`
  ${tw`
    mt-1 
    space-x-6
    flex
    flex-wrap
    w-full
    items-start
    md:items-center
    justify-between 
    text-xs 
    md:text-sm 
    leading-5 
    text-gray-500
  `}
`

export const BlogCardBody = styled.div`
  ${tw`
    px-4
    py-6
    sm:h-48
  `}
  p {
    ${tw`
      text-base
      text-purple-300
    `}
  }
  div {
    ${tw`
      mt-3
      font-bold
      text-indigo-600
    `}
  }
`

export const BlogCardFooter = styled.div`
  ${tw`
    py-5
    px-4
    flex
    flex-wrap
    justify-between
    w-full
  `}
`

export const BlogCardTextWithIcon = styled.span`
  ${tw`
    inline-flex
  `}
  div {
    ${tw`
      relative
      inline-flex
      items-center
      text-xs
      md:text-sm
      leading-5
      font-medium
      text-gray-500
    `}
  }
  button {
    ${tw`
      relative
      inline-flex
      items-center
      text-xs
      md:text-sm
      leading-5
      font-medium
      text-gray-500
      outline-none
      hover:text-gray-500
    `}
  }
  svg {
    ${tw`
      md:-ml-1
      mr-2
      h-4
      w-4
      md:h-5
      md:w-5
      text-gray-400
    `}
  }
`

export const BlogCardBadge = styled.span`
  ${tw`
    inline-flex
    items-center
    px-3
    py-0.5
    rounded-full
    text-xs
    md:text-sm
    font-medium
    leading-5
    bg-indigo-600
    text-white
  `}
`

export const BlogCardThemeAndDate = styled.div`
  ${tw`
    md:inline-flex
    md:items-center
  `}
  p {
    ${tw`
      pt-2
      md:pt-0
      pl-2
    `}
  }
`

export const BlogCardAuthor = styled.div`
  ${tw`
    ml-4
    mt-4
  `}
  div {
    ${tw`
      flex
      items-center
    `}
  }
`

export const BlogCardAuthorImg = styled.div`
  ${tw`
    flex-shrink-0
  `}
  img {
    ${tw`
      h-12
      w-12
      rounded-full
    `}
  }
`
