import React from 'react'
import AvatarWithText from '../avatar_with_text'
import {
  BlogCardBackground,
  BlogCardBody,
  BlogCardFooter,
  BlogCardIllustration,
  BlogCardWrapper,
} from './style'
import { Link } from 'gatsby'
import { renderReadMoreTrad } from '../../../../utils'

const FeaturedBlogCard = ({ description, author, url, image, lang, title }: any) => {
  return (
    <BlogCardWrapper>
      <Link to={url}>
        <BlogCardBackground>
          <BlogCardIllustration>
            <picture>
              <img src={image} alt={title} />
            </picture>
          </BlogCardIllustration>
          <div>
            <BlogCardBody>
              <p>{description}</p>
              <div>{renderReadMoreTrad(lang)} ➜</div>
            </BlogCardBody>
            <BlogCardFooter>
              {author !== undefined && (
                <AvatarWithText
                  name={author.name}
                  position={author.position}
                  photo={author.photo.url}
                />
              )}
            </BlogCardFooter>
          </div>
        </BlogCardBackground>
      </Link>
    </BlogCardWrapper>
  )
}

export default FeaturedBlogCard
