import React from 'react'
import FeaturedBlogCard from '../../blog/blog_card/featured_blog_card'
import BlogHeader from '../../blog/blog_header'
import BlogListItem from '../../blog/blog_list_item'
import Container from '../../elements/container'
import { HeroContent, HeroPanel, HeroWrapper } from './style'

const BlogHero = ({
  background_shape,
  heroPost,
  highlightPosts,
  localePath,
  lang,
  altLang,
}: any) => {
  return (
    <HeroWrapper>
      {background_shape}
      <Container>
        <BlogHeader
          title={heroPost.node.data.title[0].text}
          localePath={localePath}
          lang={lang}
          altLang={altLang}
        />
        <HeroContent>
          <FeaturedBlogCard
            url={`${localePath}/${heroPost.node.uid}`}
            theme={heroPost.node.data.theme}
            reading_time={heroPost.node.data.reading_time}
            title={heroPost.node.data.title[0].text}
            description={heroPost.node.data.description}
            image={heroPost.node.data.image.url}
            author={heroPost.node.data.author[0].primary}
            lang={lang}
          />
          {highlightPosts !== undefined && highlightPosts.length > 0 && (
            <HeroPanel>
              {highlightPosts.length >= 1 && (
                <>
                  <span className="label">Highlights</span>
                  <BlogListItem highlightPosts={highlightPosts} localePath={localePath} />
                </>
              )}
            </HeroPanel>
          )}
        </HeroContent>
      </Container>
    </HeroWrapper>
  )
}

export default BlogHero
