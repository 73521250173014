import styled from '@emotion/styled'
import tw from 'twin.macro'

export const HeroWrapper = styled.section`
  ${tw`
    relative
  `}
`

export const HeroContent = styled.div`
  ${tw`
    lg:flex
    lg:flex-row
    lg:justify-between
    lg:w-full
    mb-20
    lg:mb-32
  `}
`

export const HeroPanel = styled.div`
  ${tw`
    relative
    w-full
    lg:ml-28
    lg:w-1/4
    hidden
    lg:block
  `}
  .label {
    ${tw`
      font-bold
      text-purple-900
      text-2xl
    `}
  }
`
