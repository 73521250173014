import { Link } from 'gatsby'
import React from 'react'
import { Badge } from '../../elements/badge'
import { BlogListItemName, BlogListItemThemeAndAuthor, BlogListItemWrapper } from './style'

const BlogListItem = ({ highlightPosts, localePath }: any) => {
  return (
    <>
      {highlightPosts.map((post: any, index: string | number) => {
        return (
          <BlogListItemWrapper key={index}>
            <Link to={`${localePath}/${post.node.uid}`}>
              <BlogListItemName>
                <h5>{post.node.data.title[0].text}</h5>
              </BlogListItemName>
              <BlogListItemThemeAndAuthor>
                {/* @ts-ignore */}
                <Badge hasHover>{post.node.data.theme}</Badge>
                <p>by {post.node.data.body[0].primary.name}</p>
              </BlogListItemThemeAndAuthor>
            </Link>
          </BlogListItemWrapper>
        )
      })}
    </>
  )
}

export default BlogListItem
