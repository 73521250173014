import { graphql, Link } from 'gatsby'
import React, { useState } from 'react'
import AllBlogPosts from '../../components/blog/all_blog_posts'
import CallToAction from '../../components/elements/call_to_action'
import BlogHero from '../../components/hero/blog_hero'
import { BlogHeroBackground } from '../../components/hero/hero_background'
import Layout from '../../components/layout'
import SectionWrapper from '../../components/sectionWrapper'

export const query = graphql`
  query homeBlogQuery($skip: Int!, $limit: Int!, $lang: String) {
    allPrismicBlogPost(
      sort: { fields: last_publication_date, order: DESC }
      limit: $limit
      skip: $skip
      filter: { lang: { eq: $lang } }
    ) {
      edges {
        node {
          lang
          prismicId
          type
          data {
            ...author
            ...post
          }
          uid
          alternate_languages {
            id
            type
            lang
            uid
          }
          last_publication_date
        }
      }
    }
  }
`

const HomeBlog = ({ data, pageContext }: any) => {
  const { allPosts, blogHome, localePath, lang, altLang, node } = pageContext

  const newsletterData = {
    title: blogHome[0].node.data.newsletter_title,
    input_placeholder: blogHome[0].node.data.newsletter_input_placeholder,
    button_label: blogHome[0].node.data.newsletter_button_label,
  }

  const featuredPost = blogHome[0].node.data.featured_posts.featured_blogpost

  let highlightPostsArray: any[]
  let highlightPosts

  if (featuredPost !== null) {
    highlightPostsArray = blogHome[0].node.data.featured_posts.map(
      (post: any) => post.featured_blogpost,
    )
    if (highlightPostsArray[0] !== null) {
      highlightPosts = allPosts.filter((postA: any) =>
        highlightPostsArray.some((postB: any) => postA.node.prismicId === postB.id),
      )
    }
  }

  const postsList = data.allPrismicBlogPost.edges

  const formatDatePostsList = postsList.map((array: any) => ({
    node: {
      alternate_languages: array.node.alternate_languages,
      data: array.node.data,
      lang: array.node.lang,
      last_publication_date: new Date(array.node.last_publication_date).toDateString(),
      prismicId: array.node.prismicId,
      type: array.node.type,
      uid: array.node.uid,
    },
  }))

  const lastDate = new Date(
    Math.max(
      ...postsList.map((element: { node: { last_publication_date: string | number | Date } }) => {
        return new Date(element.node.last_publication_date)
      }),
    ),
  ).toDateString()

  const lastPost = formatDatePostsList.filter((date: { node: { last_publication_date: string } }) =>
    date.node.last_publication_date.includes(lastDate),
  )

  const [blogPosts, setBlogPosts] = useState(postsList)

  const renderViewAllPostTrad = (lang: string) => {
    switch (lang) {
      case 'en-us':
        return 'View all posts'
      case 'fr-fr':
        return 'Voir tous les articles'
      default:
        return 'View all posts'
    }
  }

  return (
    <Layout
      helmetTitle={blogHome[0].node.data.title[0].text}
      helmetDescription={blogHome[0].node.data.meta_description}
      helmetImage={blogHome[0].node.data.meta_image.url}
      helmetUrl={`https://www.cryptr.co${localePath}/`}
    >
      <BlogHero
        background_shape={<BlogHeroBackground />}
        heroPost={lastPost[0]}
        highlightPosts={highlightPosts}
        localePath={localePath}
        lang={lang}
        altLang={altLang}
      />
      {allPosts.length > 1 && (
        <AllBlogPosts
          posts={blogPosts.slice(1)}
          newsletterData={newsletterData}
          pageContext={pageContext}
          pagination={false}
          lang={lang}
        />
      )}
      <SectionWrapper disabledPadding="top">
        <CallToAction>
          <Link className="white main" to={`${localePath}/page/1`}>
            {renderViewAllPostTrad(lang)}
          </Link>
        </CallToAction>
      </SectionWrapper>
    </Layout>
  )
}

export default HomeBlog
