import styled from '@emotion/styled'
import tw from 'twin.macro'

export const BlogHeaderWrapper = styled.div`
  ${tw`
    my-8
    mx-auto
    max-w-screen-xl
    sm:mt-12
    md:mt-20
    xl:mt-24
  `}
`

export const BlogHeaderGrid = styled.div`
  ${tw`
    lg:flex
    lg:flex-wrap
    lg:w-full
    lg:justify-between
    lg:items-start
  `}
  div {
    ${tw`
      sm:text-center
      lg:text-left
    `}
  }
`

export const BlogHeaderTitle = styled.h2`
  ${tw`
    mt-1
    text-2xl
    tracking-tight
    leading-10
    font-normal
    text-purple-900
    sm:leading-none
    lg:text-2xl
    text-center
    md:text-left
  `}
  span {
    ${tw`
      inline-block
      font-extrabold
      text-3xl
      lg:text-5xl
    `}
  }
`

export const BlogSearchBar = styled.div`
  ${tw`
    relative
    rounded-md
    mb-12
    hidden
    md:block
  `}
  div {
    ${tw`
      absolute
      inset-y-0
      left-0
      pl-6
      flex
      items-center
      pointer-events-none
    `}
  }
  svg {
    ${tw`
      h-5
      w-5
      text-gray-400
    `}
  }
  input {
    ${tw`
      form-input
      bg-gray-200
      border-none
      block
      w-full
      py-4
      pl-16
      sm:text-sm
      sm:leading-5
      placeholder-gray-600
    `}
  }
`

export const BlogHeaderPanel = styled.div`
  ${tw`
    mt-10
    lg:mt-0
    relative
    w-full
    lg:w-1/4
    hidden
    md:block
  `}
`

export const BlogHeaderIntro = styled.div`
  ${tw`
    relative
    w-full
    lg:w-2/3
  `}
`
