import React from 'react'
import { LanguageSwitcher } from '../../lang_switcher'
import { BlogHeaderGrid, BlogHeaderIntro, BlogHeaderTitle, BlogHeaderWrapper } from './style'

const BlogHeader = ({ title, lang, localePath, altLang }: any) => {
  return (
    <BlogHeaderWrapper>
      <BlogHeaderGrid>
        <BlogHeaderIntro>
          <BlogHeaderTitle>
            The lastest news
            <br />
            <span>{title}</span>
          </BlogHeaderTitle>
        </BlogHeaderIntro>
        <LanguageSwitcher lang={lang} localePath={localePath} altLang={altLang} />
      </BlogHeaderGrid>
    </BlogHeaderWrapper>
  )
}

export default BlogHeader
